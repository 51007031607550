
import CryptoJS, { AES, enc } from "crypto-js";

// import {encodekey} from '../../views/config/config' ;


//  import config from '../../src/lib/config'
import { ENCODEKEY } from "../../src/lib/config";

export const Encryptdata = (data) => {
  try {
    // console.log("sssadasdasd" , ENCODEKEY)
    const encJson = CryptoJS.AES.encrypt(JSON.stringify(data), ENCODEKEY).toString();
    const encData = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(encJson)
    );
    return encData;
  // return data;
  } catch (error) {
    console.log("encrypt_err",error);
  }
 
  }
  
  export const Decryptdata = (data) => {
    try{
console.log("DATAAS",data);
    const decData = CryptoJS.enc.Base64.parse(data)?.toString(CryptoJS.enc.Utf8);
    // console.log("decData",decData,ENCODEKEY);
    const bytes = CryptoJS.AES.decrypt(decData, ENCODEKEY).toString(CryptoJS.enc.Utf8);
    // console.log("bytes",bytes);
   
    return JSON.parse(bytes)
    }
    catch(e){
        console.log("decrypt_err",e);
      return ''
    }
  }



export const AppenData = (data) => {
  var formdata = new FormData()
  var SendDta = Object.entries(data).map((item) => {
      if (Array.isArray(item[1])) {
          var come = item[1].map((data) => {
              if (data?.type && data?.size) {
                  //file
                  formdata.append(item[0], data)
              }
              else {
                  formdata.append(item[0], Encryptdata(data))

              }
              return formdata
          })
          return come

      }
      else {
          if (item[1]?.type && item[1]?.size) {
              //file type
              formdata.append(item[0], item[1])
          }
          else {
              formdata.append(item[0], Encryptdata(item[1]))

          }
          return formdata
      }
  })
  return SendDta
}